<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="id_customer"
              :items="customers"
              item-text="nombre"
              item-value="codigo"
              label="Escoge un Cliente"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete>
          </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                clearable
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(dates);
                    updateDateFilter();
                  "
                  >Aceptar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="12" md="2">
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="getByCustomer"
              block
            >
              Filtrar <v-icon right >
                fas fa-filter
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-btn
              :loading="loading3"
              :disabled="loading3"
              color="warning"
              class="ma-2 white--text"
              @click="getAll"
              block
            >
              Ver Todo
              <v-icon right >
                fas fa-eye
              </v-icon>
            </v-btn>
          </v-col>

        </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="transference"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >

    <template v-slot:[`item.action`]="{ item }">
        <v-tooltip top color="purple" v-if="item.transference==1">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" v-bind="attrs" v-on="on" class="mr-3" @click="uploadFile(item)">fas fa-edit</v-icon>
          </template>
          <span>Completar Transferencia</span>
        </v-tooltip>
        <v-icon v-if="item.transference==2" color="red" class="mr-2" @click="deleteItem(item)">fas fa-times</v-icon>
    </template>
    <template v-slot:[`item.path`]="{ item }">
        <v-icon 
        v-if="item.path"
        color="purple darken-3"
        @click="seeDocument(item)"
        >fas fa-file-pdf</v-icon>
      </template>
    <template v-slot:[`item.datetime`]="{ item }">
        {{ dateFormat(item.datetime) }}
    </template>
    <template v-slot:[`item.total_transfer`]="{ item }">
        {{ numberWithCommas(item.total_transfer) }}
    </template>
    <template v-slot:top>

          <v-dialog v-model="dialogUpload" max-width="650">
            <v-card>
              <v-card-title>
                <span>Transferencia <b> {{ numberWithCommas((parseFloat(editedItem.total_transfer)).toFixed(2)) }} </b> </span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>

                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.id_bank"
                          :items="banks"
                          :rules="editedItem.id_bankRules"
                          item-text="name"
                          item-value="id"
                          label="Escoge un Banco"
                          clearable
                          rounded
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <template>
                          <v-file-input
                            v-model="files"
                            color="deep-purple accent-4"
                            counter
                            :label="path_label"
                            placeholder="Selecciona el archivo"
                            prepend-icon="mdi-paperclip"
                            accept="application/pdf"
                            outlined
                            rounded
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </template>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          solo
                          auto-grow
                          outlined
                          rows="3"
                          v-model="editedItem.comment"
                          label="Observación"
                          :rules="editedItem.commentRules"
                        ></v-textarea>
                      </v-col>

                    </v-row>
                  </v-form>
                </v-container>

                
              </v-card-text>

              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                  <v-btn
                    class="flex-grow-1 flex-shrink-0"
                    large
                    rounded
                    outlined
                    color="gray"
                    @click="close"
                  >Cancelar</v-btn>
                  <v-btn
                    class="flex-grow-1 flex-shrink-0 white--text"
                    :loading="loading1"
                    :disabled="loading1" 
                    large
                    rounded
                    color="deep-purple"
                    @click="upload"
                    >Subir
                      <v-icon right dark > mdi-cloud-upload </v-icon>
                    </v-btn>
                </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>
    </template>

    <template slot="body.append">
      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="8" class="title">
        <v-row class="pt-2">
          <v-col cols="12" sm="12" md="4">
            Total: {{ numberWithCommas((parseFloat(sumField('total_transfer'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
    </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';

export default {
  name: "reportTransferences",
  data: () => ({
    currentItems: [],
    samplings: [],
    customers: [],
    banks: [],
    files: [],
    id_customer: null,
    dates: [],
    loading: false,
    dialogDelete: false,
    dialogUpload: false,
    loader: null,
    loading1: false,
    loading3: false,
    modal: false,
    search: "",
    editedIndex: -1,
    valid: false,
    loading: false,
    titulo: "Transferencias",
    headers: [
      { text: "Fecha", align: "left", width:"170", value: "datetime"},
      { text: "RTN", value: "rtn" },
      { text: "Productor", align: "left", value: "nomprov" },
      { text: "Observación", value: "comment" },
      { text: "Documento", sortable: false, value: "path" },
      { text: "Total", value: "total_transfer" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    
    editedItem: {
      id: "",
      comment: "",
      id_bank: null,
      commentRules: [v => !!v || "Escribe el nombre"],
      id_bankRules: [v => !!v || "Escoge el Banco"],
    },

    defaultItem: {
      comment: "",
      id_bank: null,
      commentRules: [v => !!v || "Escribe el nombre"],
      id_bankRules: [v => !!v || "Escoge el Banco"],
    },
  }),

  computed: {
    
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    path_label(){
      return this.editedItem.filename;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'report_transferences',
        {
          headers: this.headers_db
        }).then(response => {
          this.samplings = this.currentItems = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'providers',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getBanks(){
      await axios.get(this.db + 'banks',
        {
          headers: this.headers_db
        }).then(response => {
          this.banks = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getBanks(); })
        });
    },

    async upload() {
      this.loader = 'loading1'

      if (!this.editedItem.id_bank > 0) {
        this.$refs.form.validate();
        return;
      }

      if (this.files.size > 0) {
          let formData = new FormData();
          
          // files
          formData.append('files', this.files);
          formData.append('name', this.files.name);
          formData.append('id', this.editedItem.id);
          formData.append('id_bank', this.editedItem.id_bank);
          formData.append('total', this.editedItem.total_transfer);
          formData.append('comment', this.editedItem.comment);

          await axios.post(this.db + "transference/upload", formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'BEARER ' + localStorage.token
            }
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Documento subido con éxito',
              });
              this.getSamplings();
              console.log({ response });
          })
          .catch(error => {
              this.validateSession(error).then((resp) => { if(resp) this.upload(); })
          });
      } else {
          console.log("there are no files.");
      }
      this.dialogUpload = false;
    },

    async seeDocument(item){
      await axios.get(this.db + 'download/' + item.path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getCustomers();
          }
      });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
    updateDateFilter() {
      if (this.dates.length > 1) {
        if (moment(this.dates[0], "YYYY-MM-DD") > new Date(this.dates[1])) {
          this.dates = [this.dates[1], this.dates[0]];
        }
      } else {
        this.dates = [this.dates[0], this.dates[0]];
      }
    },

    uploadFile(item){
      console.log(item);
      if (item.comment == undefined) {
        item.comment = "";
      }
      // this.files.push(item.path);
      this.editedItem = Object.assign({}, item);
      this.dialogUpload = true;
    },

    close() {
      this.dialogUpload = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    getByCustomer() {
      // if (this.dates[0] && this.dates[1]){
      //   this.dates = [moment(this.dates[0]).format('YYYY-MM-DD'), moment(this.dates[1]).format('YYYY-MM-DD')];
      // }
        console.log('dates', this.dates);

      if (this.id_customer && this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          return (doc.id_provider == this.id_customer || doc.codprov == this.id_customer) && date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }
      
      else if (this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return (doc.id_provider == this.id_customer || doc.codprov == this.id_customer);
        });
      }

      else if (this.dates[0] && this.dates[1]) {
        this.currentItems = this.samplings.filter(doc => {
          let date_db = moment(doc.datetime).format('YYYY-MM-DD');
          console.log(date_db);
          return date_db >= this.dates[0] && date_db <= this.dates[1];
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getAll() {
      this.loader = 'loading3'
      this.loading = true;
      this.getSamplings();
      this.getCustomers();
    },
    eliminar() {
      console.log(this.item.id);
      // return;
      this.deleteRetention(this.item);
      this.close();
    },
  },
  mounted() {
    this.loading = true;
    this.getSamplings();
    this.getCustomers();
    this.getBanks();
  },
};
</script>