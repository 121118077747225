var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.customers,"item-text":"nombre","item-value":"codigo","label":"Escoge un Cliente","clearable":"","rounded":"","solo":"","hide-details":""},model:{value:(_vm.id_customer),callback:function ($$v) {_vm.id_customer=$$v},expression:"id_customer"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.dates,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Filtrar por fecha","prepend-icon":"fa-calendar-alt","readonly":"","filled":"","rounded":"","clearable":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","title-date-format":_vm.title,"scrollable":"","max":_vm.today},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.dates);
                    _vm.updateDateFilter();}}},[_vm._v("Aceptar")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"success","block":""},on:{"click":_vm.getByCustomer}},[_vm._v(" Filtrar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-filter ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading3,"disabled":_vm.loading3,"color":"warning","block":""},on:{"click":_vm.getAll}},[_vm._v(" Ver Todo "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" fas fa-eye ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.currentItems,"search":_vm.search,"sort-by":"transference","loading":_vm.loading,"loading-text":"Consultando datos... Espere por favor"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(item.transference==1)?_c('v-tooltip',{attrs:{"top":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.uploadFile(item)}}},'v-icon',attrs,false),on),[_vm._v("fas fa-edit")])]}}],null,true)},[_c('span',[_vm._v("Completar Transferencia")])]):_vm._e(),(item.transference==2)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("fas fa-times")]):_vm._e()]}},{key:"item.path",fn:function(ref){
                    var item = ref.item;
return [(item.path)?_c('v-icon',{attrs:{"color":"purple darken-3"},on:{"click":function($event){return _vm.seeDocument(item)}}},[_vm._v("fas fa-file-pdf")]):_vm._e()]}},{key:"item.datetime",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.datetime))+" ")]}},{key:"item.total_transfer",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total_transfer))+" ")]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"650"},model:{value:(_vm.dialogUpload),callback:function ($$v) {_vm.dialogUpload=$$v},expression:"dialogUpload"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Transferencia "),_c('b',[_vm._v(" "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.editedItem.total_transfer)).toFixed(2)))+" ")])]),_c('v-spacer')],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.banks,"rules":_vm.editedItem.id_bankRules,"item-text":"name","item-value":"id","label":"Escoge un Banco","clearable":"","rounded":"","solo":""},model:{value:(_vm.editedItem.id_bank),callback:function ($$v) {_vm.$set(_vm.editedItem, "id_bank", $$v)},expression:"editedItem.id_bank"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":_vm.path_label,"placeholder":"Selecciona el archivo","prepend-icon":"mdi-paperclip","accept":"application/pdf","outlined":"","rounded":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var index = ref.index;
                    var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})]],2),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"solo":"","auto-grow":"","outlined":"","rows":"3","label":"Observación","rules":_vm.editedItem.commentRules},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"gray"},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0 white--text",attrs:{"loading":_vm.loading1,"disabled":_vm.loading1,"large":"","rounded":"","color":"deep-purple"},on:{"click":_vm.upload}},[_vm._v("Subir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)],1)],1)],1)]},proxy:true}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"gray--text"},[_c('th',{staticClass:"title",attrs:{"colspan":"8"}},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_vm._v(" Total: "+_vm._s(_vm.numberWithCommas((parseFloat(_vm.sumField('total_transfer'))).toFixed(2)))+" ")])],1)],1)])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }